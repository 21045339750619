export const paymentSuccessfulLayout = {
  content: {
    type: "div",
    children: [
      {
        type: "div",
        children: [
          {
            type: "div",
            className: "d-flex m-auto",
            style: {
              width: "70vw",
              padding: "50px 0",
              height: "100vh"
            },
            children: [
              {
                type: "card",
                className: "text-center m-auto w-100 py-5",
                children: [
                  {
                    type: "img",
                    className: "mb-2",
                    style: { width: "150px" },
                  },
                  {
                    type: "text",
                    content: "<h6><strong>{{CONGRATULATIONS}}</strong></h6>"
                  },
                  {
                    type: "text",
                    content: "<div style=\"color: #aaaaaa\">{{REGISTRATION_SUCCESS_MESSAGE_1}}!</div>",
                    dataKey: "clientInfo.name"
                  },
                  {
                    type: "text",
                    content: "<div style=\"color: #aaaaaa\">{{REGISTRATION_SUCCESS_MESSAGE_2}} <span style=\"color: $quaternary\">${PLACEHOLDER}</span>.<br/>{{REGISTRATION_SUCCESS_MESSAGE_3}}.</div>",
                    dataKey: "userEmail"
                  },
                  {
                    type: "text",
                    content: "<div style=\"color: #aaaaaa\">{{LOGIN_ID}}: ${PLACEHOLDER}</div>",
                    dataKey: "clientUser.loginID"
                  },
                  {
                    type: "text",
                    content: "<div style=\"color: #aaaaaa\">{{PASSWORD}}: ${PLACEHOLDER}</div>",
                    dataKey: "clientUser.password"
                  },
                  {
                    type: "text",
                    content: "<div style=\"color: #aaaaaa\">{{SALES_ORDER_NUMBER}}: ${PLACEHOLDER}</div>",
                    dataKey: "createdOrder.unique_id"
                  },
                  {
                    type: "div",
                    className: "mt-5 mx-auto",
                    style: { width: "200px" },
                    children: [
                      {
                        type: "button",
                        size: "large",
                        href: "/#/login",
                        buttonType: "text",
                        className: "custom-color href-button",
                        style: { backgroundColor: "$quaternary", color: "#ffffff", textAlign: "center" },
                        children: [
                          {
                            type: "text",
                            content: "<div>{{BACK_TO_LOGIN_PAGE}}</div>"
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
}