import React from 'react'
import{ Button, ConfigProvider } from 'antd'
import i18next from 'i18next'

import './index.scss'

const UpgradeButton = props => {
  return props.windowHash.indexOf('upgrade') === -1 ?
  (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: props.clientName === 'Winna' && props.status === 'inactive' ? '#ff3d50' :'#ffec3d',
        },
      }}
    >
      {
        props.clientName === 'Winna' ? (
          <>
          {
            props.status === 'inactive' ? (
              <Button
                href={ '#/dashboard/reload-and-upgrade-mall' }
                type='default'
                className= 'mr-3 reactivate-button'>
                { i18next.t( 'REACTIVATE' )}
              </Button>
            ) : (
              <Button
                href={ '#/dashboard/reload-and-upgrade-mall' }
                type='default'
                className= 'mr-3 reload-upgrade-button'>
                { i18next.t( 'RELOAD_AND_UPGRADE' )}
              </Button>
            )
          }
          </>
        ) : (
          <Button
            href={ '#/dashboard/upgrade/mall' }
            type='primary'
            className= 'mr-3 upgrade-button'>
            { i18next.t( 'UPGRADE_NOW' )}
          </Button>
        )
      }
    </ConfigProvider>
  ) : <></>
}

export default UpgradeButton