import _ from 'lodash'

import { getContraColor } from 'utils'

const defaultColors = {
  primary: "#c1a990",
  secondary: "#e0e0db",
  tertiary: "#ffffff",
  quartenary: "#402319"
}

export const applyColor = ( style, colors ) => {
  if( typeof style === 'string' ){
    if( !_.isEmpty( style ) ){
      let tmpStyle = style.replaceAll( /\$([a-zA-Z]+)/g, ( matched ) => `${ ( !_.isEmpty( colors ) ? colors[ matched.slice(1) ] : defaultColors[ matched.slice(1) ] ) || matched }` )
      tmpStyle = tmpStyle.replaceAll( /\!([a-zA-Z]+)/g, ( matched ) => `${ getContraColor( !_.isEmpty( colors ) ? colors[ matched.slice(1) ] : defaultColors[ matched.slice(1) ] ) || matched }` )
      return tmpStyle
    } else {
      return ""
    }
  } else if ( typeof style === 'object' ) {
    if( !_.isEmpty( style ) ){
      let tmp = _.cloneDeep( style )
      _.map( tmp, ( val, key ) => { tmp[ key ] = applyColor( val, colors ) } )
      return tmp
    } else {
      return {}
    }
  } else {
    return style
  }
}