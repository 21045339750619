import React from 'react'
import { Row, Col } from 'reactstrap'
import _ from 'lodash'

import { getSelectedLanguageContent, getContraColor, thousandSeperatorFormatter } from 'utils'
import { applyColor } from 'utils/apply-color'

const WalletList = props => {
  let {
    item,
    colors,
    onClickAction,
    profile
  } = props

  return(
    <Row style={{ height: '100%' }}>
      {
        props[ item.itemList ].map( ( wallet, index ) => {
          if( 
            wallet?.settings?.hide_from && 
            (wallet.settings.hide_from.includes(profile.client_user_member?.client_tier_id) || 
            ( wallet.settings.hide_from.includes("null") && _.isEmpty(profile.client_user_member?.client_tier_id)))
          ){
            return <></>
          }
          const isActive = props[ item.activeKey ] && props[ item.activeKey ].id === wallet.id
          return (
            <Col key={ index } xs={12} md={4} className="mb-2" onClick={() => { onClickAction && onClickAction( item.actionKey, wallet )}}>
              <div
                className='d-flex align-items-center justify-content-center'
                style={{
                  color: getContraColor( isActive ? applyColor( '$primary', colors ) : applyColor( '$tertiary', colors )),
                  backgroundColor: isActive ? applyColor( '$primary', colors ) : applyColor( '$tertiary', colors ),
                  border: '1px #ccc solid',
                  height: '100%'
                }}
              >
                <div className='p-2' style={{ textAlign: 'center' }}>
                  <h5><strong>{  getSelectedLanguageContent( wallet.name )}</strong></h5>
                  <p>{ thousandSeperatorFormatter(wallet.amount) }</p>
                </div>
              </div>
            </Col>
          )
        })
      }
    </Row>
  )
}

export default WalletList
