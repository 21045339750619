export const zh = {
  translation: {
    //Language: 
  "ENGLISH": "英文",
  "MANDARIN": "中文",
  "MALAY": "马来文",
  
  //general: 
  "ACTIONS": "行动",
  "ACTIVE": "激活",
  "ADD": "添加",
  "ADDED_TO_CART": "物品已放入购物车",
  "ADJUST": "调整",
  "ALL": "全部",
  "AMOUNT": "金额",
  "ARE_YOU_SURE": "确定吗？",
  "CANCEL": "取消",
  "CAN_CREATE": "可创建",
  "CAN_DELETE": "可删除",
  "CAN_READ": "可读取",
  "CAN_UPDATE": "可更新",
  "CLEAR": "清除",
  "COLLAPSE": "折叠",
  "DATE": "日期",
  "DELETE": "删除",
  "EDIT": "编辑",
  "EXPAND": "展开",
  "FALSE": "否",
  "FILTER": "筛选",
  "FILTER_BY": "筛选条件",
  "IMAGE": "图片",
  "IMAGES": "图片",
  "INACTIVE": "未激活",
  "INDEX_NO": "编号",
  "COMBINATION": "组合",
  "INVALID_COMBINATION": "组合无效",
  "MONTH": "月份",
  "PLEASE_PICK_A_MONTH": "请选择月份",
  "PLEASE_PICK_A_YEAR": "请选择年份",
  "NAME": "姓名",
  "NO": "否",
  "NONE": "无",
  "PAGE": "页码",
  "ENTER": "输入",
  "PLEASE_ENTER": "请填写",
  "PLEASE_ENTER_THE": "请输入",
  "PLEASE_ENTER_YOUR": "请填写您的",
  "PLEASE_SELECT": "请选择",
  "SELECT": "选择",
  "SELECT_ALL": "选择全部",
  "PLEASE_SELECT_A": "请选择一个",
  "PLEASE_SELECT_AN": "请选择一个",
  "PLEASE_SELECT_YOUR": "请选择您的",
  "SELECT_YOUR": "选择您的",
  "SELECT_A": "选择一个",
  "REMARKS": "备注",
  "REMOVED_FROM_CART": "物品已从购物车中移除",
  "RESET": "重置",
  "SEARCH": "搜索",
  "SESSION_EXPIRED_PLEASE_RELOGIN": "登陆已过期请重新登录",
  "SHOW": "显示",
  "STATUS": "状态",
  "STATUS_UPDATE_SUCCESSFULLY": "状态更新成功",
  "SUBMIT": "提交",
  "SUBTOTAL": "小计",
  "SUBTOTAL_AMOUNT": "小计金额",
  "TERMS_AND_CONDITIONS": "条款和条件",
  "TOTAL": "总计",
  "TRUE": "是",
  "UPDATE": "更新",
  "UPDATE_SUCCESSFUL": "更新成功",
  "VIEW": "查看",
  "WELCOME": "欢迎",
  "YES": "是",
  "ACCEPT": "接受",
  "REJECT": "拒绝",
  "REJECT_ALL": "拒绝全部",
  "EXTRA": "额外",
  "SAVE": "保存",
  "NO_DATA_FOUND": "未找到数据",
  "REMOVE": "移除",
  "APPLY": "申请",
  "DETAILS": "详情",
  "VIEW_CAPITALIZE": "查看",
  "DOWNLOAD": "下载",
  "YEAR": "年份",

  //Monts
  "JANUARY": "一月",
  "FEBRUARY": "二月",
  "MARCH": "三月",
  "APRIL": "四月",
  "MAY": "五月",
  "JUNE": "六月",
  "JULY": "七月",
  "AUGUST": "八月",
  "SEPTEMBER": "九月",
  "OCTOBER": "十月",
  "NOVEMBER": "十一月",
  "DECEMBER": "十二月",
  
  //Menu: 
  "ANNOUNCEMENT": "公告",
  "ANNOUNCEMENT_LIST": "公告列表",
  "BANNER_LIST": "横幅列表",
  "CHECKOUT_CODES": "结账代码",
  "CLIENT_CONFIGUTATIONS": "客户设置",
  "COMMISSIONS_REPORT": "佣金报告",
  "COMMISSION_DETAIL_REPORT": "佣金详细报告",
  "COMMISSION_SUMMARY_REPORT": "佣金汇总报告",
  "CONTACT_US": "联系我们",
  "CONVERT": "转换",
  "DASHBOARD": "仪表板",
  "DISPENSE_LIST": "发货分配清单",
  "INVENTORY": "库存",
  "INVENTORY_REPORT": "库存报告",
  "MALL": "商城",
  "MEMBER_LIST": "会员列表",
  "MEMBER_SALES_AND_FULFILMENT": "会员销售和执行",
  "ORDERS": "订单",
  "ORGANISATION_CHART": "组织结构图",
  "PACKAGES": "配套",
  "PACKAGE": "配套",
  "PLACEMENT_TREE": "组织放置树",
  "PRODUCTS": "产品",
  "PRODUCT": "产品",
  "PRODUCT_PACKAGES": "产品配套",
  "PRODUCTS_CATEGORY": "产品分类",
  "PRODUCTS_MANAGEMENT": "产品管理",
  "PROFILE": "简介",
  "REDEMPTION_MALL": "兑换商城",
  "RELOAD": "充值",
  "REPORTS": "报告",
  "ROLE_PERMISSION": "权限",
  "SALES_SUMMARY_REPORT": "销售摘要报告",
  "MONTHLY_INCOME_REPORT": "每月收入报告",
  "SELF_COLLECTION_LIST": "自取清单",
  "SHIPMENT": "发货",
  "SHIPMENT_ZONE_AND_PRICINGS": "发货区域和价格",
  "SHIPPING_LIST": "发货清单",
  "SHOPPING_MALL": "购物商城",
  "SPONSOR_TREE": "组织结构树",
  "STAFF_SETTINGS": "员工设置",
  "STOCK_ADJUSTMENT_LIST": "库存调整列表",
  "STOCK_ADJUSTMENT_REPORT": "库存调整报告",
  "STOCK_FLOW_REPORT": "库存流动报告",
  "SUBADMIN_LIST": "管理员列表",
  "SYSTEM_SETTINGS": "系统设置",
  "TRANSACTION_APPROVAL": "交易审批",
  "TRANSFER": "转账",
  "USERS": "用户",
  "VOUCHER_MANAGEMENT": "优惠券管理",
  "WALLETS": "钱包",
  "WALLET_STATEMENT": "钱包报表",
  "WAREHOUSES": "仓库",
  "WAREHOUSES_MANAGEMENT": "仓库管理",
  "WITHDRAWAL": "提款",
  "UPGRADE_MALL": "升级商场",
  "DOWNLOAD_CP58": "下载CP58",
  "BULK_DOWNLOAD_CP58": "批量下载CP58",
  "MEMBER_OFFLINE_REWARD": "会员线下奖励",

  //Company
  "COMPANY_INFORMATION": "公司信息",
  "DIRECTOR_INFORMATION": "主任信息",
  "REGISTRATION_NUMBER": "注册编号",
  "DESIGNATION": "称号",

  //Offline Reward
  "OFFLINE_REWARD": "线下奖励",
  "ADD_REWARDS": "添加奖励",
  "REWARD_NAME": "奖励名称",
  "REWARD_TYPE": "奖励类型",
  "REWARD_AMOUNT": "奖励金额",
  "REWARD_RECEIVED_DATE": "奖励收到日期",
  "REWARD_CREATED_DATE": "奖励创建日期",
  "REWARD_DATE_TIME": "奖励日期",
  "REWARD_IS_CREATED": "奖励已创建",
  "REWARD_IS_UPDATED": "奖励已更新",
  "VEHICLE": "车辆",
  "HOUSE": "房子",
  "TRAVEL": "旅行",
  "OTHERS": "其他",
  
  //Header: 
  "ACCOUNT": "账户",
  "ALL_UPGRADE_REACHED": "已完成所有升级",
  "BACK_TO_ADMIN_ACCOUNT": "返回管理帐户",
  "CONFIRM": "确认",
  "CURRENT_UPGRADE": "当前等级",
  "EMPTY_CART_MESSAGE": "购物车为空。",
  "LOGOUT": "退出",
  "MALL_CART": "商城购物车",
  "NEXT_UPGRADE": "进阶等级",
  "NOTIFICATIONS": "通知",
  "PROCEED_TO_CHECKOUT": "前往结账",
  "QUANTITY": "数量",
  "ROLE": "职责",
  "SUB_TOTAL": "小计",
  "SWITCH_LANGUAGE": "切换语言",
  "UPDATE_PROFILE": "更新简介",
  "UPGRADE_CART": "升级购物车",
  "UPGRADE_NOW": "立即升级",
  "VIEW_ALL_NOTIFICATIONS": "查看所有通知",
  "VIEW_DETAIL": "查看详情",
  
  //Dashboard: 
  "NO_ANNOUNCEMENT": "目前没有公告",
  "LATEST_ANNOUNCEMENTS": "最新公告",
  "SEE_MORE": "查看更多",
  "TITLE": "标题",
  "DIRECT_RECRUITS": "直属下线",
  "LEADER": "领导",
  "MONTHLY_RETAIL_BONUS": "每月零售奖金",
  "PAST_MONTH": "上个月份",
  "POOL_BONUS": "奖池奖金",
  "TOTAL_TEAM_MEMBERS": "团队成员总数",
  "AWAITING_PAYMENT": "等待付款",
  "ORDER_STATUS": "订单状态",
  "PAST_90_DAYS": "过去90天",
  "PREPARING_SHIPMENT": "准备发货",
  "INTRANSIT": "过境",
  "SALES_TREND": "Sales Trend",
  
  //PerformanceDashboard(Member): 
  "DAILY_SALES_MYR": "每日销售额马币",
  "DAILY_SALES_SV": "每日销售额SV",
  "DIRECT_RECRUIT_SV_MONTHLY": "直接招聘SV(月)",
  "DIRECT_RECRUIT_SV_YEARLY": "直接招聘SV(年)",
  "GROUP_SV": "团队SV",
  "PAIRING_BONUS_SV_CURRENT_MONTH": "配对奖金SV(本月)",
  "PAIRING_BONUS_SV_YESTERDAY": "配对奖金SV(昨日)",
  "PERFORMANCE_DASHBOARD": "业绩仪表板",
  "PERSONAL_REPURCHASE_SV_MONTHLY": "个人回购SV(每月)",
  "PERSONAL_REPURCHASE_SV_YEARLY": "个人回购SV(每年)",
  "QUALIFIED_GROUP_SV": "合格团队SV",
  "TOTAL_COMMISSION_LAST_MONTH": "总佣金(上月)",
  "TOTAL_GROUP_SV_MONTHLY": "团队SV总额(每月)",
  "TOTAL_GROUP_SV_YEARLY": "团队SV总额(每年)",
  "MEMBER_SINCE": "会员自",
  
  //PerformanceDashboard(Admin): 
  "TOTAL_SALES_PREVIOUS_MONTH": "上月销售总额(马币)",
  "TOTAL_SALES_CURRENT_MONTH": "本月销售总额(马币)",
  "TOTAL_SALES_WHOLE_YEAR": "全年销售总额(马币)",
  "TOTAL_SALES_SV_PREVIOUS_MONTH": "上月销售总额(SV)",
  "TOTAL_SALES_SV_CURRENT_MONTH": "本月销售总额(SV)",
  "TOTAL_SALES_SV_WHOLE_YEAR": "全年销售总额(SV)",
  
  //TeamDashboard: 
  "BALANCE_SV": "余额SV",
  "GROUP": "团队",
  "MY_GROUP": "我的团队",
  "NEW": "新",
  "REAL_TIME_PLACEMENT_SV_BALANCE": "实时安置SV余额",
  "REMAINING_SV": "剩余SV",
  "TEAM_DASHBOARD": "团队仪表板",
  "TOTAL_BONUS_PAYOUT_SV": "奖金总额SV",
  "TOTAL_COMMISSION": "佣金总额",
  "TOTAL_MYR": "总额MYR",

  //Winna: 
  "CREDIT_WALLET": "积分钱包",
  "LEADER_RANKING": "销售/领导排名",
  "MEMBER_PROFILE": "会员资料",
  "MONTHLY_SALES_TARGET": "每月销售目标",
  "ORDER": "订购",
  "REPORT": "报告",
  "RESOURCES": "资源",
  "REWARD_WALLET": "奖励钱包",
  "SHOPPING": "购物",
  "TOP_LEADER": "顶级领导",
  "TOP_SALES": "顶级销售",
  "VOUCHER": "优惠券",
  
  //User: 
  "ADDRESS": "地址",
  "DATE_OF_BIRTH": "出生日期",
  "DISPLAY_NAME": "显示名称",
  "EMAIL": "电子邮件",
  "FEMALE": "女",
  "FULL_NAME": "全名",
  "GENDER": "性别",
  "INCOME_TAX_NO": "所得税号码",
  "JOINED_DATE": "注册日期",
  "KYC_APPROVAL_DATE": "KYC批准日期",
  "KYC_APPROVAL_BY": "KYC批准人",
  "KYC_SUBMISSION_DATE": "KYC提交日期",
  "MAINTENCE_STATUS": "维持费Status",
  "MALE": "男",
  "MEMBER_ID": "成员编号",
  "MOBILE": "手机",
  "MOBILE_NUMBER": "手机号码",
  "PLACEMENT_ID": "PlacementID",
  "PLACEMENT_ID_NAME": "PlacementID/Name",
  "PLACEMENT_NAME": "PlacementName",
  "RANK": "排名",
  "RANKING": "排名",
  "REGISTRATION_DATE": "注册日期",
  "SIGN_UP_DATE": "注册日期",
  "SPONSOR_ID": "推荐人编号",
  "SPONSOR_ID_NAME": "推荐人编号/姓名",
  "SPONSOR_NAME": "推荐人姓名",
  "TIER": "层级",
  "USERNAME": "用户名",
  "LOGIN_AS_MEMBER": "以会员身份登录",
  "CHANGE_PASSWORD": "更改密码",
  "CHANGE_SECURITY_PIN": "更改安全密码",
  "SUSPEND_MEMBER": "暂停会员资格",
  "UNSUSPEND_MEMBER": "重启会员资格",
  "TERMINATE_MEMBER": "终止会员",
  "VIEW_USER_DETAILS": "查看用户详细信息",
  "REFERRAL_DETAIL": "转介详情",
  "SPONSOR_DETAIL": "赞助商详情",
  "PLACEMENT_DETAIL": "安置详情",
  "DOB": "DOB",
  "REGISTRATION_TYPE_LONG": "注册类型(IC/ 护照/ SSM)",
  "PROFILE_PHOTO": "个人照片",
  "REGISTRATION_SUCCESS_MESSAGE_1": "您已成功注册 ${PLACEHOLDER} 账户",
  "REGISTRATION_SUCCESS_MESSAGE_2": "电子邮件已发送至",
  "REGISTRATION_SUCCESS_MESSAGE_3": "请检查您的电子邮件以完成注册",
  
  //Document: 
  "BACK_IC": "身份证背面",
  "DOCUMENT": "文档",
  "FRONT_IC_OR_IDENTITY_PHOTO": "身份证正面/护照/SSM注册证书",
  "IDENTITY_NUMBER": "身份证号码/护照号码/SSM号码",
  'DOCUMENT_NUMBER': '身份证/护照/SSM号码',
  
  //Address: 
  "ADDRESS_LINE_1": "地址第1行",
  "ADDRESS_LINE_2": "地址第2行",
  "CITY": "城市/省份",
  "COUNTRY": "国家",
  "POSTCODE": "邮编/邮政编码",
  "POST_CODE": "邮政编码",
  "REGION": "地区",
  "STATE": "州",
  
  //Password: 
  "CONFIRM_PASSWORD": "确认密码",
  "CURRENT_PASSWORD": "当前密码",
  "NEW_PASSWORD": "新密码",
  "UPDATE_PASSWORD": "更新密码",
  
  //SecurityPin: 
  "CONFIRM_SECURITY_PIN": "确认安全密码",
  "CURRENT_SECURITY_PIN": "当前安全密码",
  "NEW_SECURITY_PIN": "新安全密码",
  "SECURITY_PIN": "安全密码",
  "UPDATE_SECURITY_PIN": "更新安全密码",
  "FORGOT_SECURITY_PIN": "忘记安全密码",
  "ENTER_EMAIL_TO_RESET_SECURITY_PIN": "输入您的电子邮件我们将向您发送重置安全密码的链接",
  "RESET_SECURITY_PIN_EMAIL_HAS_BEEN_SENT": "重置安全密码的链接已发送到输入的电子邮件中. 请查看您的电子邮件",
  "INVALID_TOKEN": "验证令牌有问题",
  "SECURITY_PIN_HAS_BEEN_RESET": "您的安全密码已被重置。",
  
  //Bank: 
  "BANK_ACCOUNT_NO": "银行账号",
  "BANK_NAME": "银行名称",
  "BASIC_INFO": "基本资料",
  "HOLDER_NAME": "持有人姓名",
  "HOLDER_IC": "持有人身份证",
  
  //Actions&Feedbacks: 
  "ARE_YOU_SURE_UPDATE": "您确定要更新",
  "ARE_YOU_SURE_ACCEPT": "你确定要接受",
  "ARE_YOU_SURE_REJECT": "你确定要拒绝",
  "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS": "您确定要删除这个",
  "CHANGE_PASSWORD_FOR_MEMBER": "会员密码",
  "CHANGE_SECURITY_PIN_FOR_MEMBER": "更改会员安全密码",
  "MEMBER_AGE_RESTRICTION": "会员必须年满18周岁",
  "ONLY_NUMBERS": "仅限数字",
  "ONLY_ALPHANUMERIC_VALUE_IS_ACCEPTABLE": "只接受数字",
  "PASSWORD_DO_NOT_MATCH": "您输入的两个密码不匹配！",
  "SECURITY_PIN_CONFIRMATION_ERROR": "您输入的安全密码不匹配！",
  "CONFIRM_TO_ACCEPT_USER_APPLICATION": "您确定要接受该用户的申请吗?",
  "CONFIRM_TO_REJECT_USER_APPLICATION": "您确定要驳回此用户的申请吗?",
  "CONFIRM_TO_TERMINATE_USER": "您确定要终止该成员的资格吗?",
  "CONFIRM_TO_SUSPEND_USER": "您确定要暂停该成员的职务吗?",
  "CONFIRM_TO_UNSUSPEND_USER": "您确定要重启该成员的资格吗?",
  
  //Mall: 
  "MALL_TYPE":  "购物中心种类",
  "WELCOME_TO_MALL": "欢迎光临购物中心",
  "FIFTY_PERCENT_OF": "50%的",
  "HUNDRED_PERCENT_OF": "100%的",
  "RELOAD_AND_UPGRADE": "充值/升级",
  "REACTIVATE": "重新激活",
  "UPGRADE": "升级",
  "ADD_TO_CART": "添加到购物车",
  "STOCK_REMAINING": "剩余库存",
  "SELECT_YOUR_PRODUCT": "选择您的产品",
  "SELECTED": "已选定",
  "MIX_AND_MATCH": "混合与搭配",
  
  //Table: 
  "MORE_INFO": "更多信息",
  "NEXT": "下一页",
  "NO_CONTENT_CREATED": "未创建内容",
  "PAGES": "页面",
  "SEARCH_BY": "搜索方式",
  "VIEW_MORE": "查看更多",
  "NO_DATA_TO_DISPLAY": "无数据可显示",
  
  //Announcements: 
  "ADD_ANNOUNCEMENT": "添加公告",
  "ANNOUNCEMENT_IS_CREATED": "创建公告",
  "ANNOUNCEMENT_IS_UPDATED": "公告已更新",
  "AN_ERROR_OCCURED_WHILE_CREATING_THE_ANNOUNCEMENT": "创建公告时发生错误",
  "AN_ERROR_OCCURED_WHILE_UPDATING_THE_ANNOUNCEMENT": "更新公告时发生错误",
  "DEFAULT": "默认值",
  "DESCRIPTION": "描述",
  "DISPLAY_DATE": "显示日期",
  "EXPIRED_DATE": "过期日期",
  "LANGUAGE": "语言",
  "LARGE_NUMBER_WILL_DISPLAY_FIRST": "大数字将首先显示",
  "PLEASE_ENTER_A_TITLE": "请输入标题",
  "PLEASE_ENTER_THE_DISPLAY_DATE": "请输入显示日期",
  "PLEASE_ENTER_THE_EXPIRED_DATE": "请输入过期日期",
  "PLEASE_SPECIFY_A_LANGUAGE": "请指定语言",
  "PRIORITY_ORDER": "优先顺序",
  "POPUP": "弹出",
  "TYPE": "类型",
  "UPDATE_ANNOUNCEMENT": "更新公告",
  "UPLOAD_IMAGE": "上传图片",
  
  //Banner-List: 
  "ADD_BANNER": "添加横幅",
  "UPDATE_BANNER": "更新横幅",
  
  "CATEGORY": "类别",
  "REGISTRATION_MALL": "注册商城",
  "SORT": "排序",
  "FIRST": "第一",
  "SECOND": "第二",
  "THIRD": "第三类",
  
  "RECOMMENDED_SIZE": "建议尺寸为1440pxx548px",
  "PLEASE_ENTER_SORT": "请输入排序编号",
  
  "LOAD_BANNER_LIST_ERROR": "出现错误",
  "ADD_BANNER_SUCCESS": "横幅已添加",
  "ADD_BANNER_FAILED": "添加横幅时发生错误",
  "UPDATE_BANNER_SUCCESS": "标语已更新",
  "UPDATE_BANNER_FAILED": "更新横幅时发生错误",
  
  "NORMAL_MALL": "正常商城",
  
  //Checkout: 
  "CHECKOUT": "结账",
  "MY_ADDRESS": "我的地址",
  "WALLET": "钱包",
  "BALANCE": "余额",
  "PAID_AMOUNT": "已付金额",
  
  "PICK_ITEMS": "挑选物品",
  "PLEASE_SELECT_A_PAYMENT_METHOD": "请选择付款方式",
  "THERE_IS_NOTHING_IN_THE_CART_FOR_CHECKOUT": "购物车中没有任何商品。请添加一些产品或包裹以继续购物",
  "THERE_IS_NO_ADDRESS_REGISTERED_UNDER_THIS_ACCOUNT": "此账户下没有注册地址。请联系管理员了解详情",
  "ADD_ADDRESS_SUCCESS": "地址已添加",
  "PLEASE_UPLOAD_YOUR_PAYMENT_RECEIPT": "请在继续购物前上传您的付款收据",
  "PICK_1_ITEM_TO_CONTINUE": "选择1个项目继续",
  "PICK_AT_LEAST_1_ITEM_TO_CONTINUE": "至少选择1个项目才能继续",
  "PLEASE_SELECT_A_WALLET_FOR_PAYMENT": "请选择一个钱包付款",
  "PLEASE_ENTER_MOBILE_NUMBER": "请输入您的手机号码",
  "PLEASE_ENTER_CITY": "请输入城市",
  "SELECT_WAREHOUSE": "选择仓库",
  "PLEASE_UPLOAD_PAYMENT_RECEIPT": "请上传您的付款收据后再继续",
  "PLEASE_ENTER_THE_SECURITY_PIN": "请输入安全码",
  "ENTER_VOUCHER_CODE": "输入优惠券代码",
  "ORDER_HAS_BEEN_CREATED": "您的订单已生成",
  "PLEASE_WAIT_TRANSACTION_APPROVE": "请等待您的交易被批准",
  "PLEASE_PROCEED_TO_CHECKOUT_AGAIN": "请重新进行结账",
  "YOUR_ORDER_PAYMENT_IS_UNSUCCESSFUL": "您的订单付款失败",
  "PLEASE_DO_NOT_ATTEMPT_PAYMENT_AGAIN": "请不要尝试再次支付相同款项，除非订单状态已更改为失败或您希望再次购买",
  "PLEASE_CONTACT_ADMIN": "请联系管理员或使用其他付款方式重新注册",
  "YOUR_ORDER_PAYMENT_IS_PENDING": "您的订单付款正在等待银行确认",
  "PLEASE_WAIT_PATIENTLY": "请耐心等待，如果付款成功，收据将发送到您的电子邮箱",
  "PAYMENT_PENDING": "待付款",
  "SALES_ORDER_NUMBER": "订单编号",
  "RETURN_TO_DASHBOARD": "返回仪表板",
  "RETURN_TO_WALLET_RELOAD": "返回钱包充值",
  "RETURN_TO_CHECKOUT": "返回到结账",
  "MANUAL_TRANSFER_OR_ATM": "手动转账/ATM",
  "ONLINE_TRANSFER_OR_FPX": "在线转账/FPX",
  "CHECKOUT_CODE_IS_NEEDED": "此产品需要结账代码",
  
  "CHANGE": "更改地址",
  "ADD_NEW_ADDRESS": "添加新地址",
  "SET_AS_DEFAULT_ADDRESS": "设置为默认地址",
  "ONLINE_TRANSFER": "线上转账",
  "CARD": "卡",
  
  //ContactUs: 
  "EDIT_CONTACT_US": "编辑联系我们",
  "CONTENT_IS_ADDED": "内容已添加",
  "CONTENT_IS_UPDATED": "更新内容",
  "DOWNLOAD_BA_APPLICATION_FORM": "下载BA申请表",
  
  //Wallets: 
  "WALLET_TYPE": "钱包类型",
  
  //WinnaDashboard: 
  "WELCOME_TO": "欢迎使用我们的新系统",
  
  //ErrorLabels: 
  "EMAIL_IS_REGISTERED": "输入的电子邮件已经注册过。请继续登录或使用其他电子邮件注册",
  "USERNAME_IS_REGISTERED": "输入的用户名已被使用请选择新的用户名",
  "EMAIL_IS_INVALID": "提供的电子邮件无效。请提供格式正确的有效电子邮件",
  "REGISTRATION_LINK_INVALID": "注册链接有问题",
  "REGISTER_AGE_RESTRICTION": "仅允许18岁以上用户注册",
  "IC_REGISTERED_BEFORE": "IC号码之前已注册",
  "THERE_IS_NOT_ENOUGH_STOCK": "库存不足",
  
  //Filter: 
  "SEARCH_BY_SALES_ORDER_NO": "按销售订单号搜索",
  "SEARCH_BY_SALES_INVOICE_NO": "按销售订单发票号搜索",
  "SEARCH_BY_MEMBER_ID": "按会员ID搜索",
  "SEARCH_BY_MEMBER_FULL_NAME": "按会员全名搜索",
  "SEARCH_BY_SALE_TYPES": "按销售类型搜索",
  "SEARCH_BY_PAYMENT_METHODS": "按购买方式搜索",
  "SEARCH_BY_ORDER_DATE": "按订单日期搜索",
  "SEARCH_BY_TRANSACTION_APPROVAL_DATE": "按交易批准日期搜索",
  "SEARCH_APPROVED_BY": "按批准日期搜索",
  "SEARCH_BY_COLLECTION_METHODS": "按收款方式搜索",
  "SEARCH_BY_SALES_STATUS": "按销售状态搜索",
  
  "SEARCH_BY_MEMBER_USERNAME": "按会员用户名搜索",
  "SEARCH_BY_MEMBER_EMAIL": "按会员电子邮件搜索",
  "SEARCH_BY_MEMBER_MOBILE_NO": "按会员手机号码搜索",
  "SEARCH_BY_MEMBER_STATUS": "按会员状态搜索",
  "SEARCH_BY_MEMBER_TIER": "按会员等级搜索",
  "SEARCH_BY_MEMBER_RANK": "按会员等级搜索",
  "SEARCH_BY_SPONSOR_ID": "按赞助商ID搜索",
  "SEARCH_BY_SPONSOR_NAME": "按赞助商名称搜索",
  "SEARCH_BY_PLACEMENT_ID": "按配售ID搜索",
  "SEARCH_BY_REGISTRATION_DATE": "按注册日期搜索",
  
  //ForgetPassword: 
  "FORGOT_PASSWORD": "忘记密码？",
  "ENTER_EMAIL": "输入您的电子邮件我们将向您发送重置密码的链接",
  "EMAIL_HERE": "在此发送电子邮件",
  "SEND_REQUEST": "发送请求",
  "PREVIOUS": "上一个",
  
  //Inventory: 
  "ADJUSTMENT_TYPE": "调整类型",
  "CREATED_DATE": "创建日期",
  "FROM_PACKAGE": "来自配套",
  "STOCK_BALANCE": "库存余额",
  "STOCK_SUCCESSFULLY_UPDATED": "库存已成功更新",
  "AN_ERROR_OCCURED_WHILE_UPDATING_THE_STOCK": "更新库存时发生错误",
  "LOW_STOCK_ALERT_QUANTITY": "低库存警报数量",
  "STOCK_QUANTITY": "库存数量",
  "STOCK_ADJUSTMENT": "库存调整",
  "PLEASE_SPECIFY_THE_TYPE": "请指定类型",
  "PLEASE_SPECIFY_THE_ADJUSTMENT_TYPE": "请指定调整类型",
  "REPLENISHMENT": "补货",
  "ADJUSTMENT": "调整",
  "INCREASE": "增加",
  "REDUCE": "减少",
  "SUCCESSFUL PAYMENT":"付款成功",
  "PAYMENT FAILED":"付款失败",
  "ADJUSTMENT (INCREASE)":"调整 (增加)",
  "ADJUSTMENT (REDUCE)":"调整 (减少)",
  "ADJUSTMENT (REPLENISHMENT)":"调整 (补货)",
  "ORDER CREATED":"订单创建",
  "ORDER CANCELED":"订单取消",
  "STOCK_ALERT": "库存警报",
  "STOCK_CODE": "库存代码",
  "STOCK_NAME": "库存名称",
  "AMOUNT_STOCK_IN": "库存进数量",
  "AMOUNT_STOCK_OUT": "库存出数量",
  "REMAINING_QUANTITY": "剩余数量",
  "WAREHOUSE": "仓库",
  "WAREHOUSE_FOR_SELF_PICKUP": "仓库自取",
  
  //KYC: 
  "PERSONAL_INFORMATION": "个人信息",
  "BANK_DETAIL": "银行详细信息",
  "SELECT_YOUR_BANK": "选择您的银行",
  "SECURITY_PIN_ERROR": "请输入6位密码",
  "SUBMIT_SUCCESS": "您的提交已发送",
  "PENDING_VERIFICATION_MESSAGE": "感谢您填写KYC表格。请允许管理员花一些时间处理申请",
  "VERIFICATION_FAILED_MESSAGE": "管理员拒绝了您的申请请联系管理员获取更多信息",
  "STATUS_UPDATE_SUCCESS": "验证状态已成功更新",
  "REUPLOAD_IC": "重新上传IC",
  "CLOSE": "关闭",
  "PLEASE_CHECK_ALL_FIELD_IS_FILLED_UP": "请检查所有必填字段是否填写完毕",
  "PLEASE_UPLOAD_IC_FRONT": "请上传IC正面照片",
  "FULL_NAME_AS_PER_IC": "全名(根据 IC)/公司名称",
  "FULL_NAME_OR_COMPANY_NAME": "全名(根据 IC)/公司名称",
  "REGISTRATION_TYPE": "登记类型",
  "FRONT_IC_OR_PASSPORT": "正面 IC/护照",
  "SSM_REGISTRATION_CERT": "SSM 注册证书",
  "SSM_REGISTRATION_CERT_WITH_IC": "附带 IC 的 SSM 注册证书",
  "PLEASE_UPLOAD_SSM_CERT": "请上传 SSM 注册证书",
  
  "ENTER_CURRENT_PASSWORD": "输入当前密码",
  "ENTER_NEW_PASSWORD": "输入新密码",
  "PLEASE_ENTER_NEW_PASSWORD": "请输入新密码",
  "ENTER_CONFIRM_PASSWORD": "输入确认密码",
  "ENTER_CURRENT_SECURITY_PIN": "输入当前安全密码",
  "ENTER_NEW_SECURITY_PIN": "输入新安全密码",
  "ENTER_CONFIRM_SECURITY_PIN": "输入确认安全密码",
  
  //Enum: 
  "IC": "IC",
  "PASSPORT": "护照",
  "BUSINESS_REGISTRATION_NO": "商业注册号",
  
  //Login: 
  "ENTER_USERNAME": "请输入您的用户名",
  "ENTER_PASSWORD": "请输入您的密码",
  "PASSWORD_HERE": "请输入密码",
  "PASSWORD": "密码",
  "VERIFICATION_CODE": "验证码",
  "LOGIN": "登录",
  "LOGIN_ID": "登录ID",
  "PICK_A_PRODUCT": "选择产品",
  "PICK_A_PACKAGE": "选择一个配套",
  "PICK_A_PRODUCT_PACKAGE": "选择产品/配套",
  "VERIFICATION_EMAIL_HAS_BEEN_SENT": "验证电子邮件已发送到输入的电子邮件中请查看您的电子邮件",
  "PASSWORD_HAS_BEEN_RESET": "您的密码已被重置。请使用新密码登录",
  "CAPTCHA": "验证码",
  //ORDER: 
  "ORDER_DETAILS": "订单详细信息",
  
  "TIME": "时间",
  "PAYMENT_METHOD": "付款方式",
  "COLLECTION_METHOD": "收款方式",
  "INVOICE_NO": "发票号",
  "SALES_ORDER_NO": "销售订单号",
  "ORDER_UNIQUE_ID": "订单唯一ID",
  "AMOUNT_PAID": "已付金额",
  "INVOICE": "发票",
  "DO": "DO",
  "SALES": "销售",
  "SALES_TYPES": "销售类型",
  "SALES_AMOUNT": "销售金额",
  "TOTAL_SALES_AMOUNT": "销售总额",
  "SALES_SV": "销售SV",
  "TOTAL_SALES_SV": "销售总额SV",
  "SALES_PV": "销售额PV",
  "TRANSACTION_APPROVAL_DATE": "交易批准日期",
  "APPROVED_BY": "批准人",
  
  "VIEW_SALES_ORDER": "查看销售订单",
  "VIEW_INVOICE": "查看发票",
  "VIEW_DELIVERY_ORDER": "查看交货单",
  
  //OrderDetailsModal: 
  "ITEM": "物品",
  "ITEM_TYPE": "物品类型",
  "PACKAGE_DISTRIBUTOR": "配套(分销商)",
  "VOUCHER_DISCOUNT": "凭证折扣",
  
  "PAYMENT_SUCCESS": "付款成功",
  "PAYMENT_FAILED": "付款失败",
  "PENDING": "待处理",
  "REJECTED": "拒绝",
  "PACKAGED": "已打包",
  "SHIPPED": "已发货",
  "DELIVERED": "已交付",
  "COLLECTED": "已收取",
  "CANCELED": "取消",
  "PROCESSING": "处理中",
  "PENDING_VERIFICATION": "待核查",
  "REGISTRATION_FAILED": "注册未通过",
  "VERIFICATION_FAILED": "验证失败",
  "SUSPENDED": "暂停",
  "TERMINATED": "终止",
  "PENDING_REGISTRATION_UPGRADE": "待注册升级",
  "UFIT_TERMINATION": "UFIT 终止",
  "USER_HAS_BEEN_TERMINATED": "该用户已被终止",
  
  "REGISTRATION_SALES": "注册销售",
  "SHOPPING_SALES": "重复销售",
  "UPGRADE_SALES": "升级销售",
  "REDEMPTION_SALES": "赎回销售",
  
  //Package: 
  "CREATE_PACKAGE": "创建配套",
  "PACKAGE_NAME": "配套名称",
  "PACKAGE_DETAILS": "配套详细信息",
  "PACKAGE_SEQUENCE": "配套序列",
  "PACKAGE_DESCRIPTION": "配套描述",
  "PACKAGE_TYPE": "配套类型", 
  "PACKAGE_CODE": "配套编号",
  "PRICE": "价格",
  "MYR": "马币",
  "POINTS": "点数",
  "FOREIGN_PRICE": "国外价格",
  "FOREIGN_PRICES": "国外价格",
  "IS_SELL_AT_UPGRADE": "是否在升级时出售？",
  "IS_SELL_AT_REGISTRATION": "是否在注册时出售？",
  "IS_SELL_AT_REDEMPTION": "是否在兑换时出售",
  "NEW_PACKAGE": "新配套",
  "PLEASE_ENTER_THE_NAME_OF_PACKAGE": "请输入配套名称",
  "PLEASE_ENTER_THE_DESCRIPTION_OF_PACKAGE": "请输入产品包描述",
  "PLEASE_ENTER_THE_PRICE": "请输入价格",
  "PLEASE_ENTER_THE_FOREIGN_PRICE": "请输入价格",
  "PLEASE_ENTER_THE_POINTS": "请输入积分",
  "PACKAGE_IS_CREATED": "配套已创建",
  "PACKAGE_IS_UPDATED": "更新配套",
  "PACKAGE_IS_REMOVED": "删除配套",
  "PACKAGE_SEQUENCE_IS_UPDATED": "配套序列已更新",
  "AN_ERROR_OCCURED_WHILE_CREATING_THE_PACKAGE": "创建配套时发生错误",
  "AN_ERROR_OCCURED_WHILE_UPDATING_THE_PACKAGE": "更新配套时发生错误",
  "AN_ERROR_OCCURED_WHILE_REMOVING_THE_PACKAGE": "移除配套时发生错误",
  "AN_ERROR_OCCURED_WHILE_UPDATING_THE_PACKAGE_SEQUENCE": "更新配套序列时发生错误",
  "ADD_REMOVE_PRODUCT": "添加/删除产品",
  "PRODUCT_IS_ADDED": "产品已添加",
  "PRODUCT_QUANTITY_IS_UPDATED": "更新产品数量",
  "PRODUCT_IS_REMOVED": "删除产品",
  "AN_ERROR_OCCURED_WHILE_CREATING_THE_PRODUCT": "创建产品时发生错误",
  "AN_ERROR_OCCURED_WHILE_UPDATING_THE_PRODUCT": "更新产品时发生错误",
  "AN_ERROR_OCCURED_WHILE_REMOVING_THE_PRODUCT": "删除产品时发生错误",
  "PLEASE_SELECT_A_PRODUCT": "请选择产品",
  "PLEASE_ENSURE_ALL_COMBINATION_OF_PRODUCT_IS_VALID": "请确保所有产品组合有效",
  "PACKAGE_IMAGE_IS_ADDED": "已添加配套图像",
  "PACKAGE_IMAGE_IS_DELETED": "已删除配套图片",
  "IMAGE_UPLOAD_FAIL_MESSAGE": "图片上传过程中出现错误。请重试",
  "IMAGE_DELETE_FAIL_MESSAGE": "删除图片时出现错误。请重试",
  "PACKAGE_IMAGE_DETAIL": "配套图像详细信息",
  "PLEASE_ENTER_THE_QUANTITY": "请输入数量",
  "PACKAGE_NOT_FOUND": "未找到包裹",
  "PLEASE_ENTER_THE_REBATE_AMOUNT": "请输入返利金额",
  "PLEASE_SELECT_A_WALLET_TYPE": "请选择钱包类型",
  "PLEASE_SELECT_AMOUNT_TYPE": "请选择金额类型",
  "REBATE_TYPE": "返利类型",
  "REBATE_AMOUNT": "返利金额",
  "IS_SELL_AT_MALL": "是否在商城销售",
  "PLEASE_ENTER_THE_PACKAGE_CODE": "请输入包裹代码",
  "PRODUCT_INCLUDED": "产品包括",
  "BASIC": "基础",
  "PRO": "专业",
  "REBATE": "返利",
  "AMOUNT_TYPE": "金额类型",
  "TIER_CHANGE": "等级变化",
  "ROLE_CHANGE": "角色转变",
  "ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_IMAGE": "您确定要删除这张图片吗？",
  "ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_PRODUCT": "您确定要删除此产品吗？?",

  //Product: 
  "CATEGORY_NAME": "类别名称",
  "PRODUCT_CATEGORY_IS_CREATED": "创建产品类别",
  "PRODUCT_CATEGORY_IS_UPDATED": "更新产品类别",
  "PRODUCT_CATEGORY_IS_REMOVED": "删除产品类别",
  "AN_ERROR_OCCURED_WHILE_CREATING_THE_CATEGORY": "创建类别时发生错误",
  "AN_ERROR_OCCURED_WHILE_UPDATING_THE_CATEGORY": "更新类别时发生错误",
  "AN_ERROR_OCCURED_WHILE_REMOVING_THE_CATEGORY": "删除类别时发生错误",
  "NEW_PRODUCT_CATEGORY": "新产品类别",
  "EDIT_PRODUCT_CATEGORY": "编辑产品类别",
  "PLEASE_ENTER_NAME_FOR_THIS_CATEGORY": "请为该类别输入名称",
  "PLEASE_ENTER_NAME_FOR_THE_NEW_CATEGORY": "请为新类别输入名称",
  "ENTER_CATEGORY_NAME": "输入类别名称",
  "CREATE_PRODUCT_CATEGORY": "创建产品类别",
  "ARE_YOU_SURE_YOU_WANT_TO_DELETE_PRODUCT_CATEGORY": "您确定要删除此产品类别吗",
  "UPDATE_SEQUENCE": "更新序列",
  "CREATE_PRODUCT": "创建产品",
  
  "NEW_PRODUCT": "新产品",
  "EDIT_PRODUCT": "编辑产品",
  "PRODUCT_NAME": "产品名称",
  "PRODUCT_CODE": "产品代码",
  "PRODUCT_CATEGORY": "产品类别",
  "PRODUCT_IS_CREATED": "创建产品",
  "PRODUCT_IS_UPDATED": "更新产品",
  "PRODUCT_SEQUENCE_IS_UPDATED": "产品序列已更新",
  "AN_ERROR_OCCURED_WHILE_UPDATING_THE_PRODUCT_SEQUENCE": "更新产品序列时发生错误",
  "PLEASE_ENTER_NAME_FOR_THE_NEW_PRODUCT": "请输入新产品的名称",
  "PLEASE_ENTER_THE_PRODUCT_CODE": "请输入产品代码",
  "PLEASE_ENTER_THE_WEIGHT_OF_THE_PRODUCT": "请输入产品重量",
  "PLEASE_SELECT_A_CATEGORY": "请选择类别",
  "PLEASE_SPECIFY_THE_PRODUCT_STATUS": "请指定产品状态",
  "PLEASE_ADD_SOME_DESCRIPTION": "请添加说明",
  "GET_BULK_UPLOAD_TEMPLATE": "获取批量上传模板",
  "ADD_VARIANT": "添加变量",
  
  "CODE": "代码",
  "WEIGHT": "重量",
  "WEIGHT_IN_KG": "重量(千克)",
  "SOLD_AT_REGISTRATION": "在注册时出售？",
  "SOLD_AT_REDEMPTION": "在兑换时出售?",
  "SOLD_AT_UPGRADE": "在升级时出售?",
  "SOLD_AT_MALL": "在商场出售?",
  "BULK_UPLOAD_PRODUCTS": "批量上传产品",
  "ALLOW_BACK_ORDER": "允许延期交货",
  
  "VARIANT": "型号",
  "VARIANTS": "型号",
  "VARIANT_NAME": "型号名称",
  "VARIANT_COMBINATION": "型号组合",
  "END_DATE": "结束日期",
  "PROMOTION": "促销",
  "START_DATE": "开始日期",
  "VARIANT_GROUP": "变量组",
  "VARIANT_LEVEL_1": "变量级别1",
  "VARIANT_LEVEL_2": "变体级别2",
  "PRODUCT_DETAILS": "产品详细信息",
  "PRODUCT_DESCRIPTION": "产品描述",
  "PRODUCT_VARIANTS": "产品变体",
  "PRODUCT_SEQUENCE": "产品序列",
  "PRODUCT_PROMOTION": "产品促销",
  "PLEASE_PICK_A_END_DATE": "请选择结束日期",
  "UPDATE_PRODUCT_VARIANTS": "更新产品型号",
  "CREATE_PRODUCT_VARIANTS": "创建产品变体",
  "CREATE_PRODUCT_PROMOTION": "创建产品促销",
  "UPDATE_PRODUCT_PROMOTION": "更新产品促销",
  "PLEASE_PICK_A_START_DATE": "请选择开始日期",
  "PRODUCT_VARIANT_IS_CREATED": "创建产品变体",
  "PRODUCT_VARIANT_IS_UPDATED": "更新产品变体",
  "PRODUCT_VARIANT_IS_REMOVED": "删除产品变体",
  "PRODUCT_PROMOTION_IS_CREATED": "创建产品促销",
  "PRODUCT_PROMOTION_IS_UPDATED": "更新产品促销",
  "PRODUCT_PROMOTION_IS_REMOVED": "删除产品促销",
  "PLEASE_ENTER_THE_VARIANT_NAME": "请输入变体名称",
  "PLEASE_SELECT_A_VARIANT_GROUP": "请选择变体组",
  "CREATE_PRODUCT_VARIANT_COMBINATION": "创建产品变体组合",
  "UPDATE_PRODUCT_VARIANT_COMBINATION": "更新产品变体组合",
  "PRODUCT_VARIANT_COMBINATION_IS_CREATED": "产品变体组合已创建",
  "PRODUCT_VARIANT_COMBINATION_IS_UPDATED": "更新产品变体组合",
  "PRODUCT_VARIANT_COMBINATION_IS_REMOVED": "删除产品变体组合",
  "PLEASE_ENTER_THE_COMBINATION_PRICE": "请输入此组合的价格",
  "PLEASE_ENTER_THE_COMBINATION_FOREIGN_PRICE": "请输入此组合的国外价格",
  "PLEASE_ENTER_THE_COMBINATION_POINTS": "请输入此组合的积分",
  "ADD_COMBINATION_PRICE": "添加组合价格",
  "ADD_COMBINATION": "添加组合",
  "COMBINATION_STATUS": "组合状态",
  
  "PLEASE_ENTER_THE_PROMOTION_FOREIGN_PRICE": "请输入该促销活动的国外价格",
  
  "NOT_AVAILABLE": "无货",
  "OUT_OF_STOCK": "缺货",
  "PLEASE_ENTER_QUANTITY": "请输入数量",
  "PRODUCT_IMAGE_IS_CREATED": "产品图片已创建",
  "PRODUCT_IMAGE_IS_REMOVED": "产品图片已删除",
  "CREATE_PRODUCT_IMAGE": "创建产品图像",
  "PRODUCT_IMAGE_DETAIL": "产品图像详细信息",
  "PLEASE_ENTER_THE_FILENAME": "请输入文件名",
  "FILE_NAME": "文件名",
  
  "WAREHOUSE_STOCK_DETAILS": "仓库库存详情",
  "ADD_STOCK": "添加库存",
  "PLEASE_SELECT_WAREHOUSE": "请选择仓库",
  "QUANTITY_SHORTFORM": "数量",
  "ENTER_QUANTITY": "输入数量",
  "ADD_STOCK_SUCCESS": "库存已添加",
  "ADD_STOCK_ERROR": "添加库存时发生错误",
  "STOCK_EXIST": "库存已存在",
  "UPDATE_STOCK": "更新库存",
  "UPDATE_STOCK_SUCCESS": "股票已更新",
  "UPDATE_STOCK_ERROR": "更新库存时发生错误",
  "DELETE_STOCK_SUCCESS": "删除库存",
  "DELETE_STOCK_ERROR": "删除库存时发生错误",
  "ARE_YOU_SURE_DELETE_STOCK": "您确定要删除此库存吗？",
  "NO_WAREHOUSE_FOUND": "未找到仓库",
  "PRODUCT_NOT_FOUND": "未找到产品",
  "SEQUENCE_IS_UPDATED": "序列已更新",
  
  "SHORT_DESCRIPTION": "简短说明",
  "LONG_DESCRIPTION": "长描述",
  "ENTER_SHORT_DESCRIPTION": "输入产品的简短描述",
  "ENTER_LONG_DESCRIPTION": "输入产品的长描述",
  "DATE_CREATED": "创建日期",
  
  //Registration: 
  "CUSTOMER_REGISTRATION": "客户注册",
  "BACK_TO_LOGIN_PAGE": "返回登录页面",
  "REGISTER_NOW": "立即注册",
  
  "ORDER_SUMMARY": "订单摘要",
  "BILLING_DETAILS": "账单详情",
  
  
  "CHOOSE_A_PACKAGE": "选择配套",
  "CHOOSE_A_PACKAGE_TYPE": "选择配套种类",
  "CONTINUE": "继续",
  "BACK": "返回",
  
  "DONE": "完成",
  "CONGRATULATIONS": "恭喜您",
  
  //Reports: 
  "BONUS": "奖金",
  "BONUS_TYPE": "奖金类型",
  "CALCULATION_DATE": "计算日期",
  "MEMBER_ID_OR_NAME_OR_IC": "会员ID/姓名/IC",
  "FROM": "来自",
  "INVOICE_ID": "发票ID",
  "POINTS_FROM_ORDER": "订单积分",
  "TOTAL_COMMISSIONS": "总佣金",
  "HIDE_RECORDS_WITH_0_COMMISSION": "隐藏佣金为0的记录",
  
  "BONUS_SUMMARY": "奖励摘要",
  "REWARD_TYPE": "奖励类型",
  "FROM_MEMBER_ID": "来自(会员ID)",
  "FROM_MEMBER_NAME": "来自(会员名称)",
  "FROM_SALES": "来自销售额",
  "FROM_AMOUNT": "来自金额",
  "FROM_LEVEL": "来自级别",
  "PERCENTAGE": "百分比",
  
  "DAILY_PAIRED_HISTORY": "每日配对历史记录",
  "BIG_ID": "大(ID)",
  "BIG_NAME": "大(名称)",
  "SUB_ID": "子(ID)",
  "SUB_NAME": "子(名称)",
  "BATCH_ID": "批次ID",
  "PAIRED_SV": "配对SV",
  
  "MONTHLY_PAIRED_HISTORY": "每月配对历史",
  "BONUS_RATE_1": "奖励率Lv1",
  "BONUS_RATE_2": "奖金率Lv2",
  "TOTAL_BONUS": "总奖金",
  "TOTAL_BONUS_EARNED": "获得的总奖金",
  "TOTAL_PAIRED_SV": "总配对SV",
  
  "PAIRING_DAILY_BALANCE": "配对每日余额",
  "DOWNLINE_ID": "下线ID",
  "DOWNLINE_NAME": "下线名称",
  "IS_BIG_LEG": "IsBigLeg",
  "SEQUENCE_NO": "序列号",
  "DAILY_INITIAL_SV": "每日初始SV",
  
  "SALES_SV_TO_MAINTENCE": "销售SV至维护",
  "TRANSACTION_DATE": "交易日期",
  "SALES_TYPE": "销售类型",
  "ORIGINAL_SV": "原始SV",
  "MAINTAIN_SV": "维护SV",
  
  "UPGRADE_PACKAGE_RANK_HISTORY": "升级包等级历史",
  "FROM_RANK": "从等级",
  "TO_RANK": "至排名",
  "FROM_RANKING": "从排名",
  "TO_RANKING": "至排名",
  "UPGRADE_DATE": "升级日期",
  
  "UPGRADE_RANK_HISTORY": "升级等级历史",
  
  "SV_BALANCE": "SV余额",
  "TOTAL_SV": "总SV",
  
  "ALL_TIMES_SALES_SV": "所有时间销售SV",
  "TOTAL_PRICE": "总价",
  
  "TOTAL_SALES": "总销售额",
  "TOTAL_DOWNLINE_SALES": "下线销售总额",
  
  "EXPORT_TO_CSV": "导出为csv",
  
  "SUM_MEMBER_REWARD_WALLET": "会员奖励钱包总和",
  "BONUS_EARNED": "获得的奖金",
  
  "PLACEMENT_DOWN_PAIR_DETAILS": "安置下线对详细信息",
  "BIG_LEG": "大腿",
  "SMALL_LEG": "小腿",
  "PAIR_SV": "配对SV",
  
  //RolePermission: 
  "ROLE_NAME": "角色名称",
  "ROLE_TYPE": "角色类型",
  "ROLE_PERMISSION_IS_UPDATED": "角色权限已更新",
  "CUSTOM_PERMISSIONS": "自定义权限",
  
  "EDIT_ROLE_PERMISSION": "编辑角色权限",
  "MODULE_NAME": "模块名称",
  
  "VIEW_PERMISSION": "查看权限",
  
  "EDIT_ROLE": "编辑角色",
  "CREATE_ROLE": "添加角色",
  
  "PLEASE_ENTER_THE_ROLE_NAME": "请输入角色名称",
  
  "ROLE_IS_CREATED": "角色已创建",
  "AN_ERROR_OCCURED_WHILE_CREATING_THE_ROLE": "创建角色时发生错误",
  
  //Shipment: 
  "DELIVERY": "交货",
  "SELF_PICKUP": "自取",
  "SABAH_SELF_PICKUP": "沙巴(自取)",
  "SHIPPING": "发货",
  "SHIPMENT_TYPE": "发货类型",
  "PURCHASER": "购买者",
  "PURCHASER_PHONE_NO": "采购商电话号码",
  "COURIER": "快递",
  "SALES_DETAILS": "销售详情",
  "SHIPPING_TO": "发货至",
  "SHIPPING_ADDRESS": "发货地址",
  "SHIPPING_DETAILS": "运输详情",
  
  "VIEW_DETAILS": "查看详情",
  "VIEW_RECEIPT": "查看收据",
  "DISPENSE_PRODUCT": "分配产品",
  "UPDATE_STATUS": "更新状态",
  "PRINT_DO": "打印D.O",
  "DELIVERY_STATUS": "交货状态",
  "CONSIGNMENT_NOTE": "托运单",
  "SHIPPING_METHOD": "发货方式",
  
  "SELECT_SHIPPING_METHOD": "选择发货方式",
  "SELECT_STATUS": "选择状态",
  "SELECT_COURIER": "选择快递",
  "ENTER_CONSIGNMENT_NOTE": "输入托运单",
  
  "UPDATE_ORDER_STATUS_SUCCESS": "状态已更新",
  "UPDATE_ORDER_STATUS_ERROR": "更新状态时发生错误",
  "GET_INVOICE_ERROR": "生成发票时发生错误",
  "GET_DO_ERROR": "生成DO时发生错误",
  
  "DATE_FROM": "日期从",
  "DATE_TO": "日期至",
  "DO_ID": "交货单Id",
  
  "delivery": "交货",
  "self-pickup": "自取",
  
  //ShipmentZone: 
  "SHIPMENT_ZONE_AND_PRICING": "装运区域和定价",
  "ZONE_PRICING": "区域定价",
  "SHIPPING_FEES_BASED_ON_WEIGHT": "根据重量收取运费",
  "SHIPPING_FEES_BASED_ON_ORDER_PRICE": "根据订单价格收取运费",
  "SHIPPING_FEES_MESSAGE_1": "注：根据订单价格优先收取运费。",
  "SHIPPING_FEES_MESSAGE_2": "如果订单同时符合重量和订单价格标准，则会考虑基于订单价格的运费。",
  "SHIPPING_ZONE_EXISTED_ERROR_MESSAGE": "该地区已经设置了相同类型的运费",
  
  //button: 
  "ADD_NEW_PRICE": "添加新价格",
  "ADD_NEW_SHIPPING_FEE": "添加新运费",
  
  //modaltitla: 
  "NEW_ZONE_PRICE": "新区域价格",
  "UPDATE_ZONE_PRICE": "更新区域价格",
  
  //labels: 
  "ZONE": "区",
  "WEIGHT_IN_KG_SMALLER_OR_EQUAL_TO": "重量(以千克为单位)(小于或等于)",
  "SHIPPING_FEE": "运费",
  "MINIMUM_PRICE_FOR_FREE_SHIPPING": "免费送货的最低价格",
  "ORDER_PRICE_MORE_THAN_OR_EQUAL_TO": "订单价格(大于或等于)",
  
  //formplaceholder: 
  "SELECT_ZONE": "选择区域",
  "SELECT_TYPE": "选择类型",
  "ENTER_WEIGHT": "输入重量(公斤)",
  "ENTER_PRICE": "输入价格",
  "ENTER_AMOUNT": "输入金额",
  
  //formvalidaition: 
  "PLEASE_SELECT_ZONE": "请选择区域",
  "PLEASE_SELECT_TYPE": "请选择类型",
  "PLEASE_ENTER_WEIGHT": "请输入重量",
  "PLEASE_ENTER_SHIPPING_FEE": "请输入运费",
  "PLEASE_ENTER_AMOUNT": "请输入金额",
  
  //actionstatus: 
  "CREATE_SUCCESS": "已创建装运区域价格",
  "CREATE_ERROR": "创建装运区域价格时发生错误",
  "UPDATE_SUCCESS": "货件区域价格已更新",
  "UPDATE_ERROR": "更新发货区域价格时发生错误",
  "DELETE_SUCCESS": "货件区域价格已删除",
  "DELETE_ERROR": "删除货运区域价格时发生错误",
  
  //alertmessage: 
  "ARE_YOU_SURE_DELETE_PRICE": "您确定要删除此价格吗？",

  //SponsorTree: 
  "ACCUMULATED": "累计",
  "ACCUMULATED_PSV": "累计PSV",
  "ACCUMULATED_PGSV": "累计PGSV",
  "CURRENT_MONTH": "当前月份",
  "CURRENT_MONTH_PSV": "当月PSV",
  "CURRENT_MONTH_PGSV": "当月PGSV",
  "DOWNLINE_SALES_AMOUNT": "下线销售金额",
  "LEVEL": "水平",
  "CURRENT_MONTH_SALES": "当月销售额",
  "DAILY_GROUP_SV": "每日组SV",
  "QUALIFIED_SV": "合格SV",
  "TOTAL_PAIRING_SV": "总配对(SV)",
  "SPONSOR_LIST": "赞助商名单",
  "ENTER_NAME_ID_TO_SEARCH": "输入名字或用户名进行搜索",
  
  //SubadminList: 
  "STAFF_REGISTRATION": "员工注册",
  "TERMINATE": "终止",
  "SUSPEND": "暂停",
  "UNSUSPEND": "重启",
  "LOGIN_AS": "登录为",
  
  "ADMIN_ID": "管理员ID",
  "IDENTITY_TYPE": "文件类型",
  "LOGIN_PASSWORD": "登录密码",
  "COUNTRY_CODE": "国家代码",
  "NATIONALITY": "国籍",
  
  "SUBADMIN_CREATED": "子管理员已创建",
  "SUBADMIN_UPDATED": "子管理员已更新",
  
  //TNC: 
  "SCROLL_TO_AGREE_MESSAGE": "请确保在点击(同意)按钮之前滚动并仔细阅读整个协议",
  "CONFIRM_ACCEPTANCE_MESSAGE": "通过点击(同意)按钮您确认接受公司的条款和条件",
  "AGREE": "同意",
  "MEMBER_ACCEPTED_TNC_ON": "会员接受条款和条件的日期",
  "TNC_ACCEPTANCE_DATE": "条款和条件接受日期",
  
  //User: 
  "USER": "用户",
  "USER_MANAGEMENT": "用户管理",
  "UFIT_GOLD": "金级",
  "UFIT_PLATINUM": "白金级",
  "UFIT_RUBY": "红宝石",
  "UFIT_DIAMOND": "钻石级",
  "UFIT_CROWN": "皇冠级",
  "IDENTIFICATION_NUMBER": "身份证号码",
  "RESIDENTIAL_ADDRESS_INFORMATION": "居民地址信息",
  
  //Voucher: 
  "PRODUCT_SKU": "产品SKU",
  "PACKAGE_SKU_NAME": "配套SKU/名称",
  "GENERATION_DATE": "生成日期",
  "CHECKOUT_CODE": "结账代码",
  "CHECKOUT_CODE_IS_CREATED": "创建结帐代码",
  "CHECKOUT_CODE_IS_UPDATED": "结帐代码已更新",
  "MAX_USAGE_PER_CODE": "每个代码的最大使用量",
  "MAX_USAGE_PER_PERSON": "每人最大使用量",
  "REDEEMED_COUNT": "兑换次数",
  "INVOICE_DATE": "发票日期",
  "DISCOUNT_TYPE": "折扣类型",
  "DISCOUNT_AMOUNT": "折扣金额",
  "DISCOUNT_MAX_CAP": "折扣最大上限",
  "VOUCHER_IS_CREATED": "创建凭证",
  "VOUCHER_IS_UPDATED": "更新优惠券",
  "VOUCHER_CODES": "优惠券代码",
  "VOUCHER_CODE": "优惠券代码",
  "ADD_VOUCHERS": "添加优惠券",
  "NO_MAXIMUM_CAP": "没有最高上限",
  "PUBLISH_DATE": "发布日期",
  "USAGE_LIMIT_PER_CODE": "每个代码的使用限制",
  "USAGE_LIMIT_PER_PERSON": "每人使用次数限制",
  
  //Wallet: 
  "WALLET_SETTINGS": "钱包设置",
  "EXPENSES": "支出已赚取",
  "EARNED": "已赚取",
  
  //Table: 
  "FROM_ID": "来自(ID)",
  "FROM_NAME": "来自(名称)",
  "TO": "至",
  "TO_ID": "至(ID)",
  "TO_NAME": "收件人(姓名)",
  "CREATED_BY": "创建者",
  "CREATED_BY_ID": "创建者(ID)",
  "CREATED_BY_NAME": "创建者(姓名)",
  "FEE": "费用",
  "FEE_TYPE": "费用类型",
  "BENEFICIARY_NAME": "受益人名称",
  "BENEFICIARY_ID_NO": "受益人ID编号",
  "BENEFICIARY_ACCOUNT_NO": "受益人账号",
  
  
  //Enum: 
  "PAYMENT": "付款",
  "ORDER_REWARD": "订单奖励",
  "PENDING_BANK_CONFIRMATION": "等待银行确认",
  "MANUAL_TRANSFER": "手动转账",
  "PAYMENT_GATEWAY": "支付网关",
  "PERCENT": "百分比",
  "FIXED": "固定",
  "ATM": "提款机",
  
  //Form: 
  "ENTER_SECURITY_PIN": "输入安全密码",
  "PLEASE_ENTER_SECURITY_PIN": "请输入安全密码",
  "ENTER_REMARKS": "输入备注",
  "PLEASE_ENTER_REMARKS": "请输入备注",
  "DEDUCT_FROM_WALLET_BALANCE": "从钱包余额中扣除？",
  
  //Admin: 
  "SETTINGS": "设置",
  "APPROVE": "批准",
  "APPROVE_ALL": "批准全部",
  "APPROVED": "已批准",
  
  //Requestmessage: 
  "PLEASE_SET_UP_SECURITY_PIN": "请设置安全密码",
  "PLEASE_COMPLETE_KYC": "请在继续操作前完成KYC",
  "UPDATE_SETTINGS_SUCCESS": "设置已更新",
  "UPDATE_SETTINGS_ERROR": "更新设置时发生错误",
  "APPROVE_SUCCESS": "已批准",
  "REJECT_SUCCESS": "拒绝",
  "REVIEW_ERROR": "审查时发生错误",
  
  //---Statement---: 
  "TRANSACTION_TYPE": "交易类型",
  
  //---Reload---: 
  "WALLET_RELOAD": "钱包充值",
  "RELOAD_TO": "充值至",
  
  "ENTER_MEMBER_ID": "输入会员ID",
  "PLEASE_ENTER_MEMBER_ID": "请输入会员ID",
  "MEMBER_NAME": "会员姓名",
  "SELECT_RELOAD_TO": "选择充值至",
  "PLEASE_SELECT_RELOAD_TO": "请选择充值至",
  "SELECT_PAYMENT_METHOD": "选择付款方式",
  "PLEASE_SELECT_PAYMENT_METHOD": "请选择付款方式",
  
  "NOT_REFUNDABLE": "不可退款",
  "RELOAD_SUCCESS": "钱包已充值",
  "RELOAD_SUCCESS_MESSAGE_1": "您的充值已成功完成",
  "RELOAD_ERROR": "充值钱包时发生错误",
  "RELOAD_FAILED": "充值失败",
  "RELOAD_FAILED_MESSAGE_1": "您的充值过程不成功",
  "RELOAD_FAILED_MESSAGE_2": "请充值或联系管理员寻求进一步帮助",
  "RELOAD_PENDING": "重装待定",
  "RELOAD_PENDING_MESSAGE_1": "您的充值流程尚未完成，正在等待银行确认",
  "RELOAD_PENDING_MESSAGE_2": "请不要尝试再次充值，除非充值状态已更改为失败或您希望进行第二次充值。",
  
  //---Transfer---: 
  "WALLET_TRANSFER": "钱包转账",
  "ENTER_FROM_MEMBER_ID": "输入会员ID",
  "PLEASE_ENTER_FROM_MEMBER_ID": "请输入会员ID",
  "TO_MEMBER_ID": "至(会员ID)",
  "ENTER_TO_MEMBER_ID": "输入会员ID",
  "PLEASE_ENTER_TO_MEMBER_ID": "请输入会员ID",
  "TO_MEMBER_NAME": "至(会员姓名)",
  
  "MINIMUM_TRANSFER_AMOUNT": "最低转账金额",
  "ENTER_MINIMUM_TRANSFER_AMOUNT": "输入最低转账金额",
  "PLEASE_ENTER_MINIMUM_TRANSFER_AMOUNT": "请输入最低转账金额",
  "TRANSFER_FEE": "转账费用",
  "ENTER_TRANSFER_FEE": "请输入转账费用",
  "PLEASE_ENTER_TRANSFER_FEE": "请输入转账费用",
  "TRANSFER_FEE_TYPE": "转账费用类型",
  "SELECT_TRANSFER_FEE_TYPE": "选择转账费用类型",
  "PLEASE_SELECT_TRANSFER_FEE_TYPE": "选择转账费用类型",
  
  "TRANSFER_SUCCESS": "金额已转入",
  "TRANSFER_ERROR": "钱包转账时出现错误",
  "MEMBER_NOT_FOUND": "未找到会员",
  //---EndofTransfer---: 
  
  //---Convert---: 
  "WALLET_CONVERT": "钱包转换",
  "FROM_WALLET": "从(钱包)",
  "SELECT_FROM_WALLET": "选择钱包",
  "PLEASE_SELECT_FROM_WALLET": "请选择钱包",
  "TO_WALLET": "至(钱包)",
  "SELECT_TO_WALLET": "选择钱包",
  "PLEASE_SELECT_TO_WALLET": "请选择钱包",
  "MINIMUM_CONVERT_AMOUNT": "最低转换金额",
  "ENTER_MINIMUM_CONVERT_AMOUNT": "输入最低转换金额",
  "CONVERT_FEE": "转换费",
  "ENTER_CONVERT_FEE": "输入转换费",
  "CONVERT_FEE_TYPE": "转换费类型",
  "SELECT_CONVERT_FEE_TYPE": "选择转换费类型",
  "PLEASE_SELECT_CONVERT_FEE_TYPE": "选择转换费用类型",
  
  "CONVERT_SUCCESS": "金额已转换",
  "CONVERT_ERROR": "转换钱包时发生错误",
  
  //---Withdrawal---: 
  "FINAL_AMOUNT": "最终金额",
  
  "MINIMUM_WITHDRAWAL_AMOUNT": "最低取款金额",
  "ENTER_MINIMUM_WITHDRAWAL_AMOUNT": "输入最低提款金额",
  "WITHDRAWAL_FEE": "提款费用",
  "ENTER_WITHDRAWAL_FEE": "输入提款费用",
  "WITHDRAWAL_FEE_TYPE": "提款费用类型",
  "SELECT_WITHDRAWAL_FEE_TYPE": "选择提款费用类型",
  "PLEASE_SELECT_WITHDRAWAL_FEE_TYPE": "选择提款费用类型",
  "IS_DEDUCT_FROM_WITHDRAWAL_AMOUNT": "从取款金额中扣除",
  "SELECT_IS_DEDUCT_FROM_WITHDRAWAL_AMOUNT": "选择是否从取款金额中扣除",
  "PLEASE_SELECT_IS_DEDUCT_FROM_WITHDRAWAL_AMOUNT": "请选择是否从取款金额中扣除",
  
  "WALLET_WITHDRAWAL": "钱包提款",
  "BANK": "银行",
  "BANK_ACCOUNT": "银行账户",
  "SELECT_BANK_ACCOUNT": "选择银行帐户",
  "PLEASE_SELECT_BANK_ACCOUNT": "请选择银行帐户",
  "WITHDRAWAL_CHARGE": "提款费用",
  "BULK_PAYMENT_FORM": "批量付款表格",
  
  "WALLET_BALANCE": "钱包余额",
  "BANK_CODE": "银行代码",
  "BANK_HOLDER": "银行持有人",
  
  "SELECT_WALLET_TYPE": "选择钱包类型",
  "PLEASE_SELECT_WALLET_TYPE": "请选择钱包类型",
  
  "WITHDRAWAL_SUCCESS": "金额已转账",
  "WITHDRAWAL_ERROR": "取款时发生错误",
  
  //---Summary---: 
  "SUMMARY": "摘要",
  "EXPORT_WALLET_SUMMARY": "导出钱包摘要",
  
  //Warehouse: 
  "CREATE_WAREHOUSE": "创建仓库",
  "NEW_WAREHOUSE": "新仓库",
  "UPDATE_WAREHOUSE": "更新仓库",
  "ID": "ID",
  "DEFAULT_WAREHOUSE": "默认仓库",
  "ENTER_ID": "输入仓库ID",
  "ENTER_NAME": "输入仓库名称",
  "ENTER_ADDRESS": "输入地址",
  "ENTER_POSTCODE": "输入邮编",
  "ENTER_CITY": "输入城市",
  "SELECT_COUNTRY": "选择国家",
  "SELECT_STATE": "选择州",
  "SELECT_CITY": "选择城市",
  "SELECT_REGION": "选择地区",
  "PLEASE_ENTER_ID": "请输入ID",
  "PLEASE_ENTER_NAME": "请输入姓名",
  "PLEASE_ENTER_ADDRESS": "请输入地址",
  "PLEASE_ENTER_POSTCODE": "请输入邮编",
  "PLEASE_SELECT_COUNTRY": "请选择国家",
  "PLEASE_SELECT_STATE": "请选择州",
  "PLEASE_SELECT_CITY": "请选择城市",
  "PLEASE_SELECT_REGION": "请选择地区",
  //actionstatus: 
  "CREATE_WAREHOUSE_SUCCESS": "仓库已创建",
  "CREATE_WAREHOUSE_ERROR": "创建仓库时发生错误",
  "ID_EXIST": "仓库ID已存在",
  "NAME_EXIST": "仓库名称已存在",
  "UPDATE_WAREHOUSE_SUCCESS": "仓库已更新",
  "UPDATE_WAREHOUSE_ERROR": "更新仓库时发生错误",
  //region: 
  "WEST_MALAYSIA": "西马来西亚",
  "EAST_MALAYSIA": "东马来西亚",
  "SINGAPORE": "新加坡",
  "INDONESIA": "印度尼西亚",

  "REUPLOAD_RECEIPT": "重新上传收据",
  "SEARCH_BY_EXPORTED": "按已发出状态搜寻",
  "EXPORTED": "已发出状态",
  "CP58": "CP58",
  }
}
