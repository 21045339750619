import React from "react"
import { Form, Input, DatePicker, InputNumber, Switch, AutoComplete } from "antd"
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons"
import _ from "lodash"

import { checkIfComponentDisabled, handleNestedObjectKey, getSelectedLanguageContent } from 'utils'
import CoreRenderFunction from "DisplayCore"

const { TextArea } = Input
const { RangePicker } = DatePicker;

const TechBaseInput = props => {
  let{
    name,
    label,
    extra,
    rules,
    style,
    className,
    inputConfig,
    variant,
    roleConfig
  } = props.item

  const processRules = rules => {
    return rules.map( rule => {
      if( Object.keys( rule ).includes('equal') ){
        return ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue( rule.equal ) === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error( getSelectedLanguageContent( rule.message, props.selectedLanguage ) ))
          },
        })
      } else {
        return {...rule, message: getSelectedLanguageContent( rule.message, props.selectedLanguage )}
      }
    })
  }

  const checkIsDisabled = () => {
    if( inputConfig.isDisabled ) {
      return checkIfComponentDisabled( props.disabledComponent, inputConfig )
    }
    return inputConfig.disabled || roleConfig && props.disabled
  }

  const processOptions = ( dataKey, value ) => {
    if( props[ dataKey ]){
      if( value ){
        return props[ dataKey ].map( item => { return { value: item[ value || 'value' ]}})
      } else {
        return props[ dataKey ]
      }
    } else {
      return []
    }
  }
  
  return(
    <Form.Item
      name={ name[0] === "$" ? handleNestedObjectKey( props, name.slice(1) ) : name }
      className={ className }
      label={ getSelectedLanguageContent( label, props.selectedLanguage ) }
      extra={ getSelectedLanguageContent( extra, props.selectedLanguage ) }
      style={ style }
      valuePropName={ variant === "switch" ? "checked" : "value" }
      rules={ rules && processRules( rules ) }>
      {
        variant === "password" && (
          <Input.Password
            size={ inputConfig.size }
            style={ inputConfig.style }
            className={ inputConfig.className }
            autoComplete="new-password"
            placeholder={ getSelectedLanguageContent( inputConfig.placeholder, props.selectedLanguage ) || "" } 
            iconRender={ visible => ( visible ? <EyeTwoTone /> : <EyeInvisibleOutlined /> )}/>
        )
      }
      {
        variant === "datepicker" && (
          <DatePicker 
            showTime={ inputConfig.showTime }
            disabled={ checkIsDisabled()}
            size={ inputConfig.size }
            style={ inputConfig.style }
            picker={ inputConfig.picker }
            className={ inputConfig.className }
            placeholder={ getSelectedLanguageContent( inputConfig.placeholder, props.selectedLanguage ) || "" } 
            disabledDate={ inputConfig.disabledDate }
          />
        )
      }
      {
        variant === "daterangepicker" && (
          <RangePicker 
            size={ inputConfig.size }
            style={ inputConfig.style }
            className={ inputConfig.className }
            allowEmpty={ inputConfig.allowEmpty }
            placeholder={ getSelectedLanguageContent( inputConfig.placeholder, props.selectedLanguage ) || "" } 
          />
        )
      }
      {
        variant === "switch" && (
          <Switch 
            size={ inputConfig.size }
            style={ inputConfig.style }
            className={ inputConfig.className }
          />
        )
      }
      {
        variant === "auto-complete" && (
          <AutoComplete
            options={ processOptions( inputConfig.dataKey, inputConfig.value )}
            placeholder={ getSelectedLanguageContent( inputConfig.placeholder, props.selectedLanguage ) || "" }
            filterOption={(inputValue, option) =>
              option?.value?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1
            }
            size={ inputConfig.size }
            style={ inputConfig.style }
            className={ inputConfig.className }
            onSelect={ props.onClickAction ? ( value ) => { props.onClickAction( inputConfig.actionKey, value )} : null }
            onSearch={ props.onClickAction ? ( value ) => { props.onClickAction( inputConfig.onSearchActionKey, value )} : null }
          />
        )
      }
      {
        variant === "textarea" && (
          <TextArea 
            disabled={ inputConfig.disabled }
            size={ inputConfig.size }
            rows={ inputConfig.rows }
            style={ inputConfig.style }
            className={ inputConfig.className }
            defaultValue={ inputConfig.defaultValue }
            placeholder={ getSelectedLanguageContent( inputConfig.placeholder, props.selectedLanguage ) || "" } 
            suffix={ inputConfig.suffix && <CoreRenderFunction { ...props } item={ inputConfig.suffix }/> }
          />
        )
      }
      {
        variant === "number" && (
          <InputNumber 
            disabled={ checkIsDisabled()}
            size={ inputConfig.size }
            style={ inputConfig.style }
            className={ inputConfig.className }
            defaultValue={ inputConfig.defaultValue }
            min={ inputConfig.min }
            max={ inputConfig.max }
            precision={ inputConfig.precision }
            step={ inputConfig.step }
            placeholder={ getSelectedLanguageContent( inputConfig.placeholder, props.selectedLanguage ) || "" } 
            suffix={ inputConfig.suffix && <CoreRenderFunction { ...props } item={ inputConfig.suffix }/> }
            controls={ inputConfig.controls }
          />
        )
      }
      {
        !variant && (
          <Input 
            disabled={ checkIsDisabled()}
            size={ inputConfig.size }
            style={ inputConfig.style }
            className={ inputConfig.className }
            defaultValue={ inputConfig.defaultValue }
            placeholder={ getSelectedLanguageContent( inputConfig.placeholder, props.selectedLanguage ) || "" } 
            suffix={ inputConfig.suffix && <CoreRenderFunction { ...props } item={ inputConfig.suffix }/> }
          />
        )
      }
    </Form.Item>
  )
}

export default TechBaseInput