import React, { useEffect } from "react";
import cx from "classnames";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { connect } from "react-redux";

import MobileMenu from "../Menu/MobileMenu";
import HeaderLogo from "./Logo";
import Userbox from "./Userbox";
import LanguageSwitcher from "../LanguageSwitcher";
import ShoppingCart from "./ShoppingCart";
import { applyColor } from "utils/apply-color";
import UpgradeButton from "./UpgradeButton";
import { getPermissions } from "utils/permissionChecker";
import { setEnableMobileMenuSmall } from "reducers/ThemeOptions";

const Header = ({
  enableMobileMenuSmall,
  enableHeaderShadow,
  history,
  colors,
  windowHash,
  profile,
  clientInfo,
  siteModules,
  permissions,
  setEnableMobileMenuSmall
}) => {
  useEffect(() => {
    setEnableMobileMenuSmall(false);
  }, []);

  return (
    <>
      <ReactCSSTransitionGroup
        component="div"
        className={cx(`app-header ${clientInfo.name}`, {
          "header-shadow": clientInfo.name === "Winna" ? false : enableHeaderShadow
        })}
        style={{ backgroundColor: applyColor(clientInfo.name === "Winna" ? "$header" : "$primary", colors) }}
        transitionName="HeaderAnimation"
        transitionAppear={true}
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <MobileMenu
          showAvatarIcon={clientInfo.name === "Winna"}
          showShoppingCartIcon={clientInfo.name === "Winna"}
          windowHash={windowHash}
        />
        <div className="app-header__heading">
          <HeaderLogo />
          <div className="app-header__page-name"></div>
        </div>
        <div
          className={cx("app-header__content", {
            "header-mobile-open": enableMobileMenuSmall
          })}
          style={{ backgroundColor: applyColor(clientInfo.name === "Winna" ? "$header" : "$primary", colors) }}
        >
          <div className="app-header-content__left"></div>
          <div className="app-header-content__right">
            {profile.role_name?.toLowerCase().indexOf("admin") === -1 &&
              getPermissions(["Mall", "Upgrade Mall"], siteModules, permissions).can_read &&
              !getPermissions(["Mall", "Upgrade Mall"], siteModules, permissions).custom_permissions
                ?.show_at_side_menu &&
              (clientInfo.name === "Winna" ||
                profile.client_user_member?.client_tier_id !== clientInfo.tiers?.[clientInfo.tiers.length - 1].id) && (
                <UpgradeButton windowHash={windowHash} clientName={clientInfo.name} status={profile.status} />
              )}
            <LanguageSwitcher buttonClass={"btn-switch-lang btn-primary"} from={"header"} />
            <div className="shopping-cart">
              {windowHash.indexOf("checkout") === -1 && <ShoppingCart windowHash={windowHash} />}
            </div>
            <Userbox history={history} />
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </>
  );
};

const mapStateToProps = (state) => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
  colors: state.ClientReducer.clientInfo.styles?.colors,
  profile: state.ProfileReducer.profile,
  siteModules: state.ClientReducer.siteModules,
  permissions: state.ProfileReducer.permissions,
  clientInfo: state.ClientReducer.clientInfo
});

export default connect(mapStateToProps, { setEnableMobileMenuSmall })(Header);
