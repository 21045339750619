import React, { useState } from 'react'
import { Form, Input, Radio } from "antd"
import { connect } from 'react-redux'
import _ from 'lodash'

import { getSelectedLanguageContent } from 'utils'
import { SUPPORTED_LANGUAGES } from 'components/LanguageSwitcher'

const MultilingualInput = props => {

  let{
    name,
    label,
    rules,
    style,
    className,
    inputConfig
  } = props.item

  const [ selectedLanguage, setSelectedLanguage ] = useState( props.selectedLanguage )

  return(
    <div>
      <Form.Item 
        label={ getSelectedLanguageContent( label, selectedLanguage ) } 
        className={ "input-label-with-language-selector" }
      > 
        <Radio.Group 
          value={ selectedLanguage } 
          onChange={ e => setSelectedLanguage(e.target.value) }
        >
          {
            _.filter( SUPPORTED_LANGUAGES, lang => props.clientInfo.language.includes( lang.id ) ).map( lang => {
              return (
                <Radio.Button value={ lang.value }>
                  { lang.label }
                </Radio.Button>
              )
            })
          }
        </Radio.Group>
      </Form.Item>
      {
        _.filter( SUPPORTED_LANGUAGES, lang => props.clientInfo.language.includes( lang.id ) ).map( lang => {
          return (
            <div style={{ display: lang.value == selectedLanguage? 'block' : 'none' }}>
              <Form.Item
                name={ `${name}-${lang.value}` }
                className={ className }
                style={ style }
                rules={ lang.value == selectedLanguage? rules : [] }
              >
                <Input 
                  disabled={ inputConfig.disabled }
                  size={ inputConfig.size }
                  style={ inputConfig.style }
                  className={ inputConfig.className }
                  defaultValue={ inputConfig.defaultValue }
                  placeholder={ getSelectedLanguageContent( inputConfig.placeholder, lang.value ) || "" } 
                />
              </Form.Item>
            </div>
          )
        })
      }
    </div>
  )
}

const mapStateToProps = state => ({ clientInfo: state.ClientReducer.clientInfo })

export default connect( mapStateToProps )( MultilingualInput )
