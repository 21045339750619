import React from 'react'

import { handleNestedObjectKey } from 'utils'

const TechBaseImage = props => {
  let {
    altText,
    src,
    dataKey,
    style,
    className
  } = props.item

  return(
    <img 
      className={ className || "" }
      style={ style || {} }
      alt={ altText }
      src={ src ? src : ( handleNestedObjectKey( props, dataKey ) || props.data.ClientReducer.clientInfo.logo_image_url ) }
    />
  )
}

export default TechBaseImage