import Axios from "axios";

import { processSite } from "utils/host";
import { setClient, setSite, setClientCountries } from "reducers/client";
import { beginAjaxCall, ajaxCallError, ajaxCallSuccess } from "./Ajax";
import getDomainURL from "utils/api";
import { getCipherHeader } from "utils/cipher-text";

export const getClient = () => (dispatch) => {
  dispatch(beginAjaxCall());
  Axios.defaults.headers = {
    "Access-Control-Allow-Origin": "*",
    Authorization: getCipherHeader(),
    Site: processSite(window.location.host),
  };
  Axios.get(`${getDomainURL()}/clients/by_site_domain/${processSite(window.location.host)}`)
    .then((response) => {
      getClientCountries(dispatch, response?.data[0].clients.id);
      dispatch(setClient(response?.data[0].clients));
      dispatch(setSite(response?.data[0].sites));
      dispatch(ajaxCallSuccess());
    })
    .catch((error) => {
      dispatch(ajaxCallError(error.response?.data.message));
    });
};

const getClientCountries = (dispatch, clientId) => {
  dispatch(beginAjaxCall());
  Axios.defaults.headers = {
    "Access-Control-Allow-Origin": "*",
    Authorization: getCipherHeader(),
    Site: processSite(window.location.host),
  };
  Axios.get(`${getDomainURL()}/clients/${clientId}/countries`)
    .then((response) => {
      dispatch(setClientCountries(response?.data));
      dispatch(ajaxCallSuccess());
    })
    .catch((error) => {
      dispatch(ajaxCallError(error.response?.data.message));
    });
};
