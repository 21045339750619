import React from "react"
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper'
import _ from 'lodash'

import { handleNestedObjectKey } from 'utils'
import 'swiper/css/pagination'
import "./index.scss"

const TechBaseSwiper = props => {
  let {
    dataKey,
    autoplay,
    pagination,
  } = props.item

  return (
    <>
      <Swiper
        loop={ true }
        modules={[ Autoplay, Pagination ]}
        autoplay={ autoplay }
        pagination={ pagination }
        className='techbase-images-slider mb-2'>
        {
          handleNestedObjectKey( props, dataKey )?.map(( data, index ) => (
            <SwiperSlide key={ index }>
              <img src={ data.url } alt={ data.title } />
            </SwiperSlide>
          ))
        }
      </Swiper>
    </>
  )
}

export default TechBaseSwiper