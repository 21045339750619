import _ from 'lodash'
import { getSelectedLanguageContent } from "utils"
import { requestSuccess } from 'utils/requestHandler'

export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART'
export const ADD_PACKAGE_TO_CART = 'ADD_PACKAGE_PRODUCT_TO_CART'
export const REMOVE_PRODUCT_FROM_CART = 'REMOVE_PRODUCT_FROM_CART'
export const REMOVE_PACKAGE_FROM_CART = 'REMOVE_PACKAGE_FROM_CART'
export const CLEAR_CART = 'CLEAR_CART'
export const CLEAR_UPGRADE_CART = 'CLEAR_UPGRADE_CART'
export const CLEAR_REDEMPTION_CART = 'CLEAR_REDEMPTION_CART'
export const ADD_PENDING_PAYMENT = 'ADD_PENDING_PAYMENT'
export const REMOVE_PENDING_PAYMENT = 'REMOVE_PENDING_PAYMENT'


const initialState = {
  cartProducts: [],
  cartPackages: [],
  cartUpgradeProducts: [],
  cartUpgradePackages: [],
  cartRedemptionProducts: [],
  cartRedemptionPackages: [],
  pendingPayment: []
}

export const addProductToCart = payload => ({
  type: ADD_PRODUCT_TO_CART,
  ...payload
})

export const removeProductFromCart = payload => ({
  type: REMOVE_PRODUCT_FROM_CART,
  payload,
})

export const addPackageToCart = payload => ({
  type: ADD_PACKAGE_TO_CART,
  ...payload,
})

export const removePackageFromCart = payload => ({
  type: REMOVE_PACKAGE_FROM_CART,
  payload,
})

export const clearCart = payload => ({
  type: CLEAR_CART,
  payload,
})

export const clearUpgradeCart = payload => ({
  type: CLEAR_UPGRADE_CART,
  payload,
})

export const clearRedemptionCart = payload => ({
  type: CLEAR_REDEMPTION_CART,
  payload,
})

export const addPendingPayment = payload => ({
  type: ADD_PENDING_PAYMENT,
  ...payload
})

export const removePendingPayment = payload => ({
  type: REMOVE_PENDING_PAYMENT,
  ...payload
})

export default ( state = initialState, action ) => {
  let tmpProduct = _.cloneDeep(( window.location.hash.indexOf('upgrade') > -1 ? state.cartUpgradeProducts : window.location.hash.indexOf('redemption') > -1 ? state.cartRedemptionProducts : state.cartProducts ) || [])
  let tmpPackages = _.cloneDeep(( window.location.hash.indexOf('upgrade') > -1 ? state.cartUpgradePackages : window.location.hash.indexOf('redemption') > -1 ? state.cartRedemptionPackages : state.cartPackages ) || [])
  let tmpPendingPayment = _.cloneDeep( state.pendingPayment ) || []
  switch( action.type ) {
    case ADD_PRODUCT_TO_CART: 
      let tmpProductItem = _.find( tmpProduct, { id: action.payload.id, variant_combination_id: action.payload.variant_combination_id } )
      if( tmpProductItem ){
        tmpProductItem.quantity += action.payload.quantity
        if( tmpProductItem.quantity <= 0 ){
          let removeProductIndex = _.findIndex( tmpProduct, { id: action.payload.id, variant_combination_id: action.payload.variant_combination_id } )
          if( removeProductIndex > -1 ){
            tmpProduct.splice( removeProductIndex, 1 )
          }
        }
      } else {
        tmpProduct.push( action.payload )
      }
      !action.disableToast && requestSuccess( getSelectedLanguageContent("{{ADDED_TO_CART}}") )
      return window.location.hash.indexOf('upgrade') > -1 ? {
        ...state,
        cartUpgradeProducts: action.is_mix_and_match_available ? tmpProduct : [ action.payload ]
      } : window.location.hash.indexOf('redemption') > -1 ? {
        ...state,
        cartRedemptionProducts: tmpProduct
      } : {
        ...state,
        cartProducts: tmpProduct
      }
    case REMOVE_PRODUCT_FROM_CART:
      let removeProductIndex = _.findIndex( tmpProduct, { id: action.payload } )
      if( removeProductIndex > -1 ){
        tmpProduct.splice( removeProductIndex, 1 )
      }
      requestSuccess( getSelectedLanguageContent("{{REMOVED_FROM_CART}}") )
      return window.location.hash.indexOf('upgrade') > -1 ? {
        ...state,
        cartUpgradeProducts: tmpProduct
      } : window.location.hash.indexOf('redemption') > -1 ? {
        ...state,
        cartRedemptionProducts: tmpProduct
      } : {
        ...state,
        cartProducts: tmpProduct
      }
    case ADD_PACKAGE_TO_CART:
      let tmpPackageItem = _.find( tmpPackages, val => val.id === action.payload.id && _.isEqual( val.products, action.payload.products ) )
      if( tmpPackageItem ){
        tmpPackageItem.quantity += action.payload.quantity
        if( tmpPackageItem.quantity <= 0 ){
          let removePackageIndex = _.findIndex( tmpPackages, val => val.id === action.payload.id && _.isEqual( val.products, action.payload.products ) )
          if( removePackageIndex > -1 ){
            tmpPackages.splice( removePackageIndex, 1 )
          }
        }
      } else {
        tmpPackages.push( action.payload )
      }
      !action.disableToast && requestSuccess( getSelectedLanguageContent("{{ADDED_TO_CART}}") )
      return window.location.hash.indexOf('upgrade') > -1 ? {
        ...state,
        cartUpgradePackages: action.is_mix_and_match_available ? tmpPackages : [ action.payload ]
      } : window.location.hash.indexOf('redemption') > -1 ? {
        ...state,
        cartRedemptionPackages: tmpPackages
      } : {
        ...state,
        cartPackages: tmpPackages
      }
    case REMOVE_PACKAGE_FROM_CART:
      let removePackageIndex = _.findIndex( tmpPackages, val => val.id === action.payload.id && _.isEqual( val.packageProducts, action.payload.packageProducts ) )
      if( removePackageIndex > -1 ){
        tmpPackages.splice( removePackageIndex, 1 )
      }
      requestSuccess( getSelectedLanguageContent("{{REMOVED_FROM_CART}}") )
      return window.location.hash.indexOf('upgrade') > -1 ? {
        ...state,
        cartUpgradePackages: tmpPackages
      } : window.location.hash.indexOf('redemption') > -1 ? {
        ...state,
        cartRedemptionPackages: tmpPackages
      } : {
        ...state,
        cartPackages: tmpPackages
      }
    case CLEAR_CART: 
      return {
        ...state,
        cartProducts: [],
        cartPackages: []
      }
    case CLEAR_UPGRADE_CART: 
      return {
        ...state,
        cartUpgradeProducts: [],
        cartUpgradePackages: []
      }
    case CLEAR_REDEMPTION_CART: 
      return {
        ...state,
        cartRedemptionProducts: [],
        cartRedemptionPackages: [],
      }
    case ADD_PENDING_PAYMENT:
      tmpPendingPayment.push( action )
      return {
        ...state,
        pendingPayment: tmpPendingPayment
      }
    case REMOVE_PENDING_PAYMENT: 
      let tmpState = {}
      let tmpPaymentIndex = _.findIndex( tmpPendingPayment, { value: action.value } )
      if( tmpPaymentIndex > -1 ){
        if( !action.status ){
          tmpPendingPayment.splice( tmpPaymentIndex, 1 )
          tmpState = {
            ...state,
            pendingPayment: tmpPendingPayment
          }
        } else if( tmpPendingPayment[ tmpPaymentIndex ].isUpgradeMall  ){
          tmpPendingPayment.splice( tmpPaymentIndex, 1 )
          tmpState = {
            ...state,
            cartUpgradeProducts: [],
            cartUpgradePackages: [],
            pendingPayment: tmpPendingPayment
          }
        } else if( tmpPendingPayment[ tmpPaymentIndex ].isRedemptionMall  ){
          tmpPendingPayment.splice( tmpPaymentIndex, 1 )
          tmpState = {
            ...state,
            cartRedemptionProducts: [],
            cartRedemptionPackages: [],
            pendingPayment: tmpPendingPayment
          }
        } else {
          tmpPendingPayment.splice( tmpPaymentIndex, 1 )
          tmpState = {
            ...state,
            cartProducts: [],
            cartPackages: [],
            pendingPayment: tmpPendingPayment
          }
        }
        return tmpState
      }
      return state
    default: return state
  }
}