import React from 'react'
import { Form, Radio } from 'antd'

import CoreRenderFunction from 'DisplayCore'
import { getSelectedLanguageContent } from 'utils'

const TechBaseRadio = props => {
  let{
    name,
    style,
    className,
    actionKey,
    dataKey,
    inputConfig,
  } = props.item

  return (
    <Form.Item
      name={ name }
      valuePropName='value'
    >
      <Radio.Group
        name={ name }
        style={ style }
        valuePropName='value'
        className={ className }
        onChange={ e => {
          if( props.onClickAction ) {
            props.onClickAction( actionKey, e.target.value )
          }
        }}
      >
        {
          inputConfig?.options && inputConfig.options.map( option => (
            <Radio value={ option.value }>{ getSelectedLanguageContent( option.label )}</Radio>
          ))
        }
        {
          inputConfig?.dynamic && props[ dataKey ].map( option => (
            <Radio
              style={ inputConfig.style }
              className={ inputConfig.className }
              value={ option[ inputConfig.value ]
            }>
              <CoreRenderFunction
                { ...props }
                item={ inputConfig.optionItem }
                optionData={ option }
              />
            </Radio>
          ))
        }
      </Radio.Group>
    </Form.Item>
  )
}

export default TechBaseRadio
